import axios from 'axios';

//const TEST_URL = `http://localhost:3001/`

const LIVE_URL = `https://4np4zlafng.execute-api.us-west-1.amazonaws.com/`

const URL = LIVE_URL

function getDataFunction (setter, url) {
    axios({
        method: 'GET',
        url,
    })
    .then(myData => {
        setter(myData.data.data)
    })
    .catch(err => {
        console.log(err)
    })
}

export function getOrgs (setter) {
    getDataFunction(setter, URL + `get/orgs`)
}

export function getIncidentReportByUserID (setter, userID) {
    getDataFunction( setter, URL + `incident/get/incidentreport/UserID=${parseInt(userID)}`)
}

export function clearIncidentsAndReports (setter) {
    axios({
        method: 'POST',
        url: URL + 'incident/resetMyLogs'
    })
    .then(() => {
        setter(null)
    })
    .catch(err => {
        console.log(err)
    })
}

export function getActiveIncidentsByBuildingID(setter, buildingID) {
    axios({
        method: 'GET',
        url: URL + `incident/get/incidents/active/bldgID=${buildingID}`,
    })
    .then(myData => {
        setter(myData.data.data[0])
    })
    .catch(err => {
        console.log(err)
    })
}

export function submitIncident(setter, data, url) {
    axios({
        method: 'POST',
        url: URL + url,
        data
    })
    .then(() => {
        setter(true)
    })
    .catch(err => {
        console.log(err)
    })
}

export function getAllBuildings(setter, myOrg) {
    getDataFunction(setter, URL + `get/buildings/OrgID=${myOrg}`)
}

export function getAllRooms(setter, myBldg){
    getDataFunction(setter, URL + `get/rooms/bldgID=${myBldg}`)
}

export function finishUserSignUp(userData) {
    axios({
        method: 'POST',
        url: URL + 'user/post/user/finish',
        data: userData,
    })
    .then(() => {
        console.log('Finished user')
        window.location.reload()
    })
    .catch(err => {
        console.log(err)
    })
}

export function getUserIDandBuildingID(setter, userIDExt) {
    axios({
        url: URL + `user/get/user/ExtUserID='${userIDExt.toString()}'`,
        method: 'GET'
      })
      .then(myData => {
        setter(myData.data.data[0])
      })
}

export function getCommonSituation(setter) {
    getDataFunction(setter, URL + 'get/commonsituations')
}