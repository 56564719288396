function convert12Hours(hours) {
  return hours > 12 ? hours - 12 : hours
}

export function formatTime(time, format){
  time = new Date(time);
  let timeString = '';

  for(let i = 0; i < format.length; i++){
    let char = format[i].toUpperCase();
    if(char === 'H'){
      timeString += convert12Hours((time.getHours())).toString()
    } else if (char === 'M'){
      let min = time.getMinutes();
      if(min < 10){
        min = '0' + min.toString();
        timeString += min;
      } else {
        timeString += time.getMinutes().toString()
      }
    } else if (char === 'S'){
      let sec = time.getSeconds();
      if(sec < 10){
        sec = '0' + sec.toString();
        timeString += sec;
      } else {
        timeString += time.getSeconds().toString()
      }
    } else if(char === 'Y') {
      timeString += time.getFullYear()
    } else if(char === 'G') {
      timeString += time.getMonth()
    } else if (char === 'D') {
      timeString += time.getDate()
    } else if(char === ':'){
      timeString += ':'
    } else if (char === '/'){
      timeString += '/'
    } else if (char === '-') {
      timeString += '-'
    } else if (char === 'Q') {
      timeString  += time.getHours() > 12 ? ' PM' : ' AM'
    } else if(char === '.') {
      timeString += '.'
    }
  }

  return timeString;
}