import React from 'react';
import { getActiveIncidentsByBuildingID, submitIncident, getCommonSituation } from '../../api/api'
import styled from 'styled-components'

const Form = styled.form``

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: space-around;
  margin: 20px;
`

const InputRow = styled.div`
  flex-direction: row;
  align-content: space-between;
  justify-content: center;
  align-items: center;
`

const Label = styled.label`
  color: white;
`

const NumberInput = styled.input`
  font-size: 1.25em;
  text-align: center;
  width: 10vh;
`

const TextInput = styled.input`
  font-size: 1.25em;
`

const CheckboxInput = styled.input`
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
`

const AssignedRoomButton = styled.button`
  background-color: ${props => props.selected ? "#ec2c38" : "white"};
  border: none; 
`

const SubmitButton = styled.button`
  display: inline-block;
  color: #ec2c38;
  font-size: 1em;
  margin: 1em;
  padding: 0.25em 1em;
  border: 2px solid #ec2c38;
  border-radius: 3px;
  display: block;
`

const PlusMinusButton = styled.button`
  color: #ec2c38;
  font-size: 1.25em;
  border: 1px solid #ec2c38;
  border-radius: 4px;
  background-color: white;
`

const PrePopButton = styled.button`
  color: #ec2c38;
  font-size: 0.8em;
  border: 1px solid #ec2c38;
  border-radius: 4px;
  background-color: white;
`

const NotesTextArea = styled.textarea`
  width: 85%;
  height: 15vh;
`

function LogScreen({userObj, setLogsUpdated}) {
  const [activeEvent, setActiveEvent] = React.useState(null);
  const [ emergency, setEmergency ] = React.useState(false)
  const [headCount, setHeadCount] = React.useState(20);
  const [staffCount, setStaffCount] = React.useState(1);
  const [inAssignedRoom, setInAssignedRoom] = React.useState(true);
  const [otherLocation, setOtherLocation] = React.useState();
  const [notes, setNotes] = React.useState()
  const [ commonSituations, setCommonSituations ] = React.useState()
  const [otherRoomPrePopButtons] = React.useState([
    {id: 1, value: 'Library'},
    {id: 2, value: 'Gym'},
    {id: 3, value: 'Outside'},
    {id: 4, value: 'Music Room'},
    {id: 5, value: 'Cafeteria'}
  ]);
  
  React.useEffect(() => {
    if(userObj.Building_ID){
      getActiveIncidentsByBuildingID(setActiveEvent, userObj.Building_ID)
    }
  }, [userObj.Building_ID])

  React.useEffect(() => {
    getCommonSituation(setCommonSituations)
  }, [])
  
  function handleSubmit(event) {
    event.preventDefault();

    //create the object to send
    const data = {
      eventID: activeEvent?.ID,
      userIDExt: userObj.External_User_ID,
      inRoom: inAssignedRoom,
      headCount,
      staffCount,
      otherLocation: !inAssignedRoom ? otherLocation : null,
      notes,
      buildingID: userObj.Building_ID,
      userID: userObj.ID,
      emergency
    }

    const url = `incident/post/create/incident/bldgID=${userObj.Building_ID}`

    submitIncident(setLogsUpdated, data, url)
  }

  function assignedRoomButton(event) {
    event.preventDefault();
    if(event.target.id === 'ar-yes') {
      setInAssignedRoom(true)
    } else {
      setInAssignedRoom(false)
    }
  }

  function prePopulateButton(event) {
    event.preventDefault();
    setNotes(event.target.value);
  }

  function otherLocationPrePopulateButton(event) {
    event.preventDefault();
    setOtherLocation(event.target.value);
  }

  function handleNotesChange(event) {
    setNotes(event.target.value);
  }

  function headCountButton(event) {
    event.preventDefault();
    if(event.target.id === 'plus') {
      setHeadCount(headCount + 1)
    } else {
      if(headCount > 0){
        setHeadCount(headCount - 1)
      }
    }
  }

  function staffCountButton(event) {
    event.preventDefault();
    if(event.target.id === 'plus') {
      setStaffCount(staffCount + 1)
    } else {
      if(staffCount > 1){
        setStaffCount(staffCount - 1)
      }
    }
  }
  
  function ActivateSP() {
    return (
      <InputContainer>
        <Label htmlFor='safety_pin'>Activate SafetyPin </Label>
        <CheckboxInput type='checkbox' checked={emergency} onChange={() => {setEmergency(!emergency)}}/>
      </InputContainer>
    )
  }
  
  return (
    <>
      <Form onSubmit={handleSubmit}>
        {!activeEvent ? <ActivateSP />: <InputContainer><Label>Active Event: {activeEvent.Title}</Label></InputContainer>}
        <InputContainer>
          <Label htmlFor='assignedRoom'>Are you in your assigned room? </Label>
          <InputRow>
            <AssignedRoomButton selected={inAssignedRoom ? true : false} onClick={assignedRoomButton} id='ar-yes'>Yes</AssignedRoomButton>
            <AssignedRoomButton selected={!inAssignedRoom ? true : false} onClick={assignedRoomButton} id='ar-no'>No</AssignedRoomButton>
          </InputRow>
        </InputContainer>
        <InputContainer>
          {!inAssignedRoom ? 
          <>
            <InputRow>
              {
                otherRoomPrePopButtons.map(btn =>
                  <PrePopButton key={btn.id} value={btn.value} onClick={otherLocationPrePopulateButton}>{btn.value}</PrePopButton>  
                  )
                }
            </InputRow>
            <Label htmlFor='otherLocation'>Current Room: </Label>
            <TextInput id='otherLocation' type='text' value={otherLocation} onChange={otherLocationPrePopulateButton}/>
          </>: null
          }
        </InputContainer>
        <InputContainer>
          <Label htmlFor='headCount'>How many students are in the room? </Label>
          <InputRow>
            <PlusMinusButton id='minus' onClick={headCountButton}>-</PlusMinusButton>
            <NumberInput type='number' id='headCount' value={headCount} onChange={(e) => setHeadCount(e.target.value)} />
            <PlusMinusButton id='plus' onClick={headCountButton}>+</PlusMinusButton>
          </InputRow>
        </InputContainer>
        <InputContainer>
          <Label htmlFor='staffCount'>How many staff are in the room? </Label>
          <InputRow>
            <PlusMinusButton id='minus' onClick={staffCountButton}>-</PlusMinusButton>
            <NumberInput type='number' id='staffCount' value={staffCount} onChange={(e) => setStaffCount(e.target.value)} />
            <PlusMinusButton id='plus' onClick={staffCountButton}>+</PlusMinusButton>
          </InputRow>
        </InputContainer>
        <InputContainer>
          <Label htmlFor='notes'>Notes: </Label>
          <InputRow>
            {
              commonSituations?.map(btn => 
                <PrePopButton key={btn.ID} value={btn.Name} onClick={prePopulateButton}>{btn.Name}</PrePopButton>
              )
            }
          </InputRow>
          <NotesTextArea id='notes' value={notes} onChange={handleNotesChange}/>
        </InputContainer>
        <InputContainer>
          <SubmitButton type='submit'>Submit</SubmitButton>
        </InputContainer>
      </Form>
    </>
  )
}

export default LogScreen;